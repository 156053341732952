<template>
	<div>


		<div style="height:80vh;overflow: auto;">
			<div style="text-align:center">
				<el-select v-model="activeName" style="width:300px" @change="setKpItem()">
					<el-option v-for="(item, idx) in tabList" :key="idx" :label="item.kp_title"
						:value="item.id"></el-option>
				</el-select>
			</div>

			<div style="padding-top: 20px;">
				<processmaker :code="'kaipiao_'+activeName" :title="'开票方：'+KPTitle" v-if="activeName&&showProcess"></processmaker>

			</div>
		</div>





	</div>
</template>

<script>
import processmaker from '../../com/processmaker'
export default {
	components: {
		processmaker
	},
	data() {
		return {
			activeName: "",
			tabList: [],
			showProcess:true,
			KPTitle:""


		}
	},

	mounted() {

		this.getZengzhi()



	},
	methods: {

		changeTab() {
			console.log(this.activeName)
		},
		setKpItem(e){
			for(let item of this.tabList){
				if(item.id==this.activeName){
					this.KPTitle =item.kp_title
					break
				}
			}
		
			this.showProcess = false;
			setTimeout(()=>{
				this.showProcess = true;
			},500)
		},
		getZengzhi() {
			this.$http.post("/api/sys_kp_company_list").then(res => {
				let tabList = [];
				for (let item of res.data) {
					if (item.com_type == 2) {
						tabList.push(item)
					}
				}
				this.tabList = tabList
				this.activeName = tabList[0].id
				this.KPTitle = tabList[0].kp_title
			})
		}




	}
}
</script>
