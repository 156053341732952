<template>
	<div class="cbox">
		<div class="headbar">

			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>开票结算</el-breadcrumb-item>
					<el-breadcrumb-item>开票结算审批流程配置</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">
			<el-tabs v-model="activeName" @tab-click="changeTab">
				<el-tab-pane v-for="(item, idx) in tabList" :key="idx" :label="item.name" :name="item.name">
				</el-tab-pane>

			</el-tabs>

			<div style="height:80vh;overflow: auto;">
				<processmaker code="kaipiao1" title="开票方：红叶保险" v-if="activeName == '开票方：红叶保险'"></processmaker>
				<!-- <processmaker2 code="kaipiao2" title="开票方：增值服务" v-if="activeName == '开票方：增值服务'"></processmaker2> -->
				<zengzhi  v-if="activeName == '开票方：增值服务'"></zengzhi>
				<processmaker3 code="jiesuan1" title="结算流程：代理人" v-if="activeName == '结算流程：代理人'"></processmaker3>
				<processmaker4 code="jiesuan2" title="结算流程：个人非代理人" v-if="activeName == '结算流程：个人非代理人'"></processmaker4>
				<processmaker5 code="jiesuan3" title="结算流程：对公账户" v-if="activeName == '结算流程：对公账户'"></processmaker5>
			
				<div style="height: 50px;">

				</div>
			</div>




		</div>


	</div>
</template>

<script>
import processmaker from '../com/processmaker'
import processmaker2 from '../com/processmaker'
import processmaker3 from '../com/processmaker'
import processmaker4 from '../com/processmaker'
import processmaker5 from '../com/processmaker'
import zengzhi from './zengzhi/zengzhilist'
export default {
	components: {
		processmaker,processmaker2,
		processmaker3,processmaker4,processmaker5,
		zengzhi
	},
	data() {
		return {
			activeName: "开票方：红叶保险",
			tabList: [{
				name: "开票方：红叶保险"
			}, {
				name: "开票方：增值服务"
			}, {
				name: "结算流程：代理人"
			}, {
				name: "结算流程：个人非代理人"
			}, {
				name: "结算流程：对公账户"
			}],


		}
	},

	mounted() {





	},
	methods: {

		changeTab() {
			console.log(this.activeName)
		}




	}
}
</script>
